import { Component, HostListener, Injector, OnInit } from '@angular/core';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { BaseService } from 'app/views/services/base.service';
import { API } from 'app/views/shared/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header-top-sessions',
  templateUrl: './header-top-sessions.component.html',
  styleUrls: ['./header-top-sessions.component.css'],
  animations: [egretAnimations]
})
export class HeaderTopSessionsComponent extends BaseService implements OnInit {

  layoutConf:any;
  public menuItems: any[];
  private menuItemsSub: Subscription;
  constructor(
    injector:Injector
  ) { 
    super(injector);
    this.navService.getMenuItems();
  }

  ngOnInit(): void {
    this.layoutConf = this.layout.layoutConf;
    this.menuItemsSub = this.navService.getMenuItems().subscribe(menuItem => {
        this.menuItems = menuItem.filter(item => item.type !== 'icon' && item.type !== 'separator');
      });
    }

  ngAfterViewInit() {
    this.menuItemsSub = this.navService.getMenuItems().subscribe(menuItem => {
      this.menuItems = menuItem.filter(item => item.type !== 'icon' && item.type !== 'separator');
      this.menuItems.push(
        {
          state: "signin",
          name: "Iniciar sesión",
          type: "link"
        },
        {
          state: "signup",
          name: "Registrarme",
          type: "link"
        }
      );
    });
  }

  search():void {}

  toggleSidenav() {
    this.showMenu = !this.showMenu;
    if(this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  changeSidenav(data) {
    this.navService.publishNavigationChange(data.value);
  }

  goTo(url:string){
    if (url.includes('home')) {
      window.location.replace(API.URL_BASE_FRONT + (url).replace('/',''));
    } else {
      this.router.navigateByUrl(url,{replaceUrl:true});
    } 
  }

  getActiveRouteClass(url): string {
    let activeClass:string = '';
    if (this.router.url === url) {
      activeClass = 'active-route';
    }
    return activeClass;
  }
  showMenu:boolean = false;
  clickCount:number = 0;
  @HostListener('document:click')
  clickOutside(event) {
    if (this.showMenu && this.clickCount <= 0) {
      this.clickCount++;
      return;
    }
    if (this.showMenu && this.clickCount > 0) {
      this.showMenu = false;
      this.clickCount = 0;
      return;
    }
  }
}

